<template>
  <div>
    <div class="card">
      <div style="background: #DBE7F2" class="p-2">
        <TitleButton
          :showBtn="false"
          :showSettingBtn="false"
          :showAddNew="false"
          :title="headerTitle"
          @onClickCloseButton="onClickCloseButton"
        />

        <div class="row mt-2 gy-1">
          <div class="row mt-1">
            <div class="col-sm-4">
              <label class="align-middle">CS Date</label>
              <input type="date" class="form-control" :value="csData.cs_date" readonly>
            </div>
            <div class="col-sm-4">
              <label class="align-middle">CS No</label>
              <input type="text" class="form-control" :value="csData.cs_number" readonly>
            </div>
            <div class="col-sm-4">
              <label class="align-middle">Requester</label>
              <input type="text" class="form-control" :value="user" readonly>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-sm-4">
              <label class="align-middle">Requisition No</label>
              <input type="text" class="form-control" :value="csData.requisition_number" readonly>
            </div>
            <div class="col-sm-4">
              <label class="align-middle">Requisition Date</label>
              <input type="date" class="form-control" :value="csData.requisition_date" readonly>
            </div>
            <div class="col-sm-4">
              <label class="align-middle">Department</label>
              <input type="text" class="form-control" :value="department" readonly>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-sm-4">
              <label class="align-middle">Project</label>
              <input type="text" class="form-control" :value="project" readonly>
            </div>
            <div class="col-sm-4">
              <label class="align-middle">Business</label>
              <input type="text" class="form-control" :value="business" readonly>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="cs-table table-responsive mt-2 pb-2">
          <table class="table table-bordered table-striped">
            <thead>
            <tr>
              <th colspan="2" class="text-center">Supplier Details</th>
              <th colspan="9" class="text-center" v-for="supplier in csData.suppliers">
                {{ supplier.full_name }}
              </th>
            </tr>
            <tr>
              <th>Particulars</th>
              <th>Req Qty</th>
              <template v-for="supplier in csData.suppliers">
                <th>Specification</th>
                <th>Quantity</th>
                <th>Unit price / Amount</th>
                <th>VAT %</th>
                <th>VAT Amount</th>
                <th>Total Amount</th>
                <th>Quantity (Confirm)</th>
                <th>Unit price / Amount (Confirm)</th>
                <th>Total Amount (Confirm)</th>
              </template>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index1) in csData.table_data">
              <td>{{ item.particular }}</td>
              <td>{{ item.qty }}</td>
              <template  v-for="(supplier, index2) in csData.suppliers">
                <td>{{ getItemData(supplier.id, 'item_specification', item.supplier_data) }}</td>
                <td class="text-right">{{ getItemData(supplier.id, 'quantity', item.supplier_data) }}</td>
                <td class="text-right">{{ getItemData(supplier.id, 'rate', item.supplier_data) }}</td>
                <td class="text-right">{{ getItemData(supplier.id, 'vat', item.supplier_data) }}</td>
                <td class="text-right">{{ getItemData(supplier.id, 'vat_amount', item.supplier_data) }}</td>
                <td class="text-right">{{ getItemData(supplier.id, 'total_amount', item.supplier_data) }} </td>
                <td class="text-right">
                   <span v-if="getItemData(supplier.id, 'quantity', item.supplier_data) === '-'">
                    {{ getItemData(supplier.id, 'quantity', item.supplier_data) }}
                  </span>
                  <vField v-else
                      :value="getItemData(supplier.id, 'quantity_confirm', item.supplier_data)"
                      name="quantity_confirm"
                      type="number"
                      class="form-control text-right"
                      @change="calculateConfirmTotal($event.target.value, supplier.id, 'quantity_confirm', item.supplier_data)"
                      :readonly="viewOnly"
                  />
                </td>
                <td class="text-right">
                   <span v-if="getItemData(supplier.id, 'rate', item.supplier_data) === '-'">
                    {{ getItemData(supplier.id, 'rate', item.supplier_data) }}
                  </span>
                  <vField v-else
                      :value="getItemData(supplier.id, 'rate_confirm', item.supplier_data)"
                      name="rate_confirm"
                      type="number"
                      class="form-control text-right"
                      @change="calculateConfirmTotal($event.target.value, supplier.id, 'rate_confirm', item.supplier_data)"
                      :readonly="viewOnly"
                  />
                </td>
                <td class="text-right">
                  <span v-if="getItemData(supplier.id, 'total_amount', item.supplier_data) === '-'">
                    {{ getItemData(supplier.id, 'total_amount', item.supplier_data) }}
                  </span>
                  <input v-else
                      :value="getItemData(supplier.id, 'total_amount_confirm', item.supplier_data)"
                      name="total_amount_confirm"
                      type="text"
                      class="form-control text-right"
                      readonly
                  />
                </td>
              </template>
            </tr>

            <tr class="d-none">
              <td colspan="2" class="text-center"><strong>Total</strong></td>
              <template v-for="supplier in csData.suppliers">
                <td colspan="5" class="text-right"><strong>
                  {{ getTotalAmount(supplier.id, csData.table_data) }}
                </strong> </td>
              </template>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="px-2 mt-3 mb-3">
        <div class="row mt-3 px-2">
          <div class="col-md-12 row justify-content-center">
            <div class="col-lg-7 col-12 text-center border-dashed">
              <div class="d-flex flex-wrap justify-content-between align-middle">
                <BlobImageViewer
                  v-for="(file, index) in csData?.additional_images"
                  :file="file"
                  :key="index"
                  :index="index"
                >
                  <template v-slot:handler>
                    <img
                      class="position-absolute cursor-pointer top-0"
                      width="15"
                      src="/app-assets/images/icons/trash.svg"
                      alt=""
                    >
                  </template>
                </BlobImageViewer>
              </div>
              <div class="pt-2">
                <div>
                  <!--TODO: Setup bind functions and remove hidden attribute from input-->
                  <input hidden>
                  <p>Drop the files here ...</p>
                  <p>Drag 'n' drop some additional images</p>
                </div>
                <button @click.prevent="open" class="btn btn-outline-primary">open</button>
              </div>
            </div>
          </div>
          <div class="col-12 mt-3">
            <div class="mb-2">
              <label for="note" class="form-label fw-bold">Terms & Condition</label>
              <textarea
                v-model="csData.description"
                placeholder="Memo"
                class="form-control"
                rows="2"
                id="description" readonly></textarea>
            </div>
          </div>
          <div class="col-lg-12 col-12 mt-1">
            <div class="mb-1">
              <label class="form-label" for="note">Comments</label>
              <textarea
                v-model="comment"
                placeholder="Comments"
                class="form-control"
                rows="1"
                :readonly="viewOnly"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="mt-2 d-flex justify-content-start justify-content-sm-center px-2 gap-1 flex-wrap pb-2"
      >
        <hr class="hr-full-width mb-1">
        <slot v-if="!viewOnly">
          <button
            :disabled="saveButtonLoader|| (csData.approved_by_self > 0)"
            class="btn btn-danger"
            @click="changeStatus('rejected')">
            Reject
          </button>

          <button
            :disabled="saveButtonLoader|| (csData.approved_by_self > 0)"
            class="btn btn-primary"
            @click="changeStatus('returned')"
          >
            Return
          </button>

          <button
            :disabled="saveButtonLoader|| (csData.approved_by_self > 0)"
            class="btn btn-success"
            @click="changeStatus((csData.status !== 'pending_approve') ? 'pending_approve' : 'approved')"
          >
            {{getConfirmButtonName()}}
          </button>
          <button @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
        </slot>
        <button @click="$refs.viewApprovalMatrix.updateShowApprovalMatrix(true)" class="btn btn-warning">Show More</button>
      </div>
    </div>

    <view-approval-matrix-details
      :workflowDetails="workflowDetails"
      :approvalLogs="csData.workflow_approval_logs ?? []"
      ref="viewApprovalMatrix"
    />
    <GlobalLoader/>
  </div>
</template>

<script setup>
import TitleButton                        from '@/components/atom/TitleButton'
import {inject, ref, onMounted, computed} from 'vue';
import {useRoute, useRouter}              from "vue-router";
import {useStore}                         from 'vuex';
import BlobImageViewer                    from '@/components/atom/BlobImageViewer'
import handleRequisitions                 from "@/services/modules/procurement/requisition";
import handleCompany                      from "@/services/modules/company";
import figureFormatter                    from "@/services/utils/figureFormatter";
import ViewApprovalMatrixDetails from "@/components/molecule/approval-matrix/ViewApprovalMatrixDetails.vue";
import handleWorkflow from "@/services/modules/approvalMatrix/workflowMatrix";
import GlobalLoader from "@/components/atom/GlobalLoader.vue";
const {fetchCompanyInfo} = handleCompany();
const {commaFormat} = figureFormatter();

const router = useRouter()
const route = useRoute()
const supplier = ref(null)
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const store = useStore()
const {
  fetchSingleRequisition,
  updateCSStatus,
  fetchSingleCSForPrint
} = handleRequisitions();
const {fetchWorkflowFromPageId} = handleWorkflow();
const companyId   = computed(() => {
  return route.params.companyId
})

let headerTitle   = ref('Review CS')
let loader        = ref(false)
let requisition   = ref({})
let user          = ref(null)
let department    = ref(null)
let project       = ref(null)
let business      = ref(null)
const csData = ref({});
let comment = ref('')
const viewOnly = ref(false);
const saveButtonLoader = ref(false);
const workflowDetails = ref({});
const approvalPageId = route.query.approval_page_id;
const workflowQuery = getQuery() + `&approval_page_id=${approvalPageId}`;

function onClickAddNewButton() {
  alert('Add New Button clicked');
}

function onClickCloseButton() {
  router.push({name: 'cs-approval', params: route.params, query: route.query})
}

function getQuery() {
  return `?company_id=${route.params.companyId}`;
}

function navigateToListPage() {
  router.push({name: `cs-approval`, params: route.params, query: route.query});
}

function setData(){
  csData.value.cs_date  = new Date(csData.value.cs_date).toISOString().split('T')[0];
  csData.value.requisition_date  = new Date(csData.value.requisition_date).toISOString().split('T')[0];
  user.value = requisition.value.user.name;
  department.value = requisition.value.department?.name;
  project.value = requisition.value.project?.name;
  business.value = requisition.value.business?.name;
}

function getItemData(supplier_id, column, supplier_data){
  const item  = supplier_data.find((item) => item.contact_profile_id == supplier_id)
  if(item) {
    if (['rate', 'vat_amount', 'total_amount', 'rate_confirm', 'total_amount_confirm'].includes(column)){
      return (column == 'rate' || column == 'rate_confirm') && item.is_product == '0'
        ? commaFormat(item.amount)
        : commaFormat(item[column]);
    }

    if(column == 'vat'){
      return (item[column]) + '%';
    }

    return item[column];
  }

  return '-'
}

function getTotalAmount(supplier_id, supplier_data){
  let res = 0.00;
  supplier_data.forEach((items)=>{
    items.supplier_data.forEach((item)=>{
      if (supplier_id == item.contact_profile_id){
        res += parseFloat(item.total_amount);
      }
    })
  })

  return commaFormat(res);
}

function calculateConfirmTotal(inputData, supplier_id, column, supplier_data){
  if (inputData == ""){
    inputData = 0;
  }
  //find supplier data
  const item  = supplier_data.find(item => item.contact_profile_id == supplier_id);

  if (item) {

    // calculate new total
    const totalQuantity = supplier_data.reduce((sum, item) => {
      return sum += item.quantity_confirm;
    }, column === 'quantity_confirm' ? parseInt(inputData) : 0);

    // check if total is more than quantity
    if(totalQuantity > item.quantity) {
      const remainingQty = item.quantity - (totalQuantity - parseInt(inputData))
      showError(`Confirm quantity cannot be more than requisition quantity. Remaining quantity: ${remainingQty}`)
      /*item[column] = parseInt(remainingQty);
      item.total_amount_confirm = calculateTotalAmount(item);
      return;*/
    }

    item[column] = parseInt(inputData);
    item.total_amount_confirm = calculateTotalAmount(item);
  }
}

function calculateSubTotal(item){
  let subTotal = 0;
  subTotal += item.is_product == "1"
             ? (parseInt(item.quantity_confirm) * parseInt(item.rate_confirm))
             : parseInt(item.rate_confirm);

  return subTotal;
}

function calculateTotalVAT(item){
  let vat = 0;

  if (item.is_product == "1" && item.vat) {
    vat += parseInt((item.vat / 100) * parseInt(item.quantity_confirm) * parseInt(item.rate_confirm))
  }
  else if(item.is_product == "0" && item.vat){
    vat += parseInt((item.vat / 100) * parseFloat(item.rate_confirm))
  }

  return vat;
}

const getConfirmButtonName = () => {
  if(csData.value.approved_by_self > 0) {
    return 'Already Approved';
  }

  if(csData.value.status === 'pending_approve') {
    return 'Approve';
  }

  return "Confirm"
}

function calculateTotalAmount(item) {
  return (calculateSubTotal(item) + calculateTotalVAT(item));
}

function changeStatus(status){
  if(status === 'approved' && ! confirm('Are you sure you want to approve this CS?')){
    return;
  }

  let statusData = {
    'status' : status,
    'workflow_master_id' : workflowDetails.value.id,
    'comment' : comment.value,
  }

  if (status === 'pending_approve'){
    statusData.cs_data = {...csData.value};
    delete statusData.cs_data.suppliers;
    let general_data = [];
    // statusData.cs_data.table_data.forEach((item)=>{
    for(const item of statusData.cs_data.table_data) {
      const totalQuantity = item.supplier_data.reduce((sum, item) => sum += item.quantity_confirm , 0)
      if(totalQuantity === 0) {
        showError(`${item.particular} confirm quantity cannot be 0!`);
        return;
      }
      Array.prototype.push.apply(general_data,item.supplier_data);
    };
    delete statusData.cs_data.table_data;
    statusData.cs_data.generals = general_data;
  }

  saveButtonLoader.value = true
  updateCSStatus(statusData, route.query.csId, getQuery())
    .then(res => {
      saveButtonLoader.value = false
      if(res.status) {
        showSuccess(res.message)
        navigateToListPage();
      }
      if(!res.status) {
        showError(res.message)
      }
    })
    .catch(err=> {
      saveButtonLoader.value = false;
      showError(err)
    })
}

function onMountedReload() {
  loader.value = true
  const fetchRequisition = fetchSingleRequisition(route.query.requisitionId, getQuery());
  const fetchCompany = fetchCompanyInfo(route.params.companyId);
  const fetchCS = fetchSingleCSForPrint(route.query.csId, getQuery());
  if (route.name === 'cs-view') {
    viewOnly.value = true;
  }

  Promise.all([
    fetchRequisition.then(res => {
      requisition.value = res.data;
    }),
    fetchCompany.then(res => {
      company.value = res.data;
    }),
    fetchCS.then(res => {
      csData.value = res.data;
      setData();
    }),
    fetchWorkflowFromPageId(workflowQuery).then(res => {
      if(res.data) workflowDetails.value = res.data
    })
  ])
      .then(() => {
        loader.value = false
      })
      .catch((err) => {
        loader.value = false
      })
}

onMounted(async () => {
  onMountedReload();
})
</script>

<style scoped>
hr {
  margin: 1rem 0;
  color: #403d4452 !important;
  background-color: currentColor;
  border: 0;
  opacity: 1;
}
.cs-table th,
.cs-table td
{
  padding: 5px 10px;
  white-space: nowrap;
}
</style>